*{
    padding: 0;
    margin: 0;
}
.parent-body{
   height: 100vh;
   width: 100vw;
   position: fixed;
   background-color: transparent;
   top: 0vw;
   left: 0vw;
   overflow: hidden;
   display: none;
}
.active-parent-body{
   display: block;
}
.main-body{
   height: 100vh;
   width: 1000vw;
   background-color: transparent;
   display: flex;
   position: absolute;
   top: 0vw;
   left: 0vw;
   transition: .5s linear;
   
}
.active-main-body{
   left: -100vw;
}
.data-body{
   height: 100%;
   width: 100vw;
   background-color: white;
   position: relative;
   display: flex;
   align-items: center;
   justify-content: center;
}
.data{

    height: 36vw;
    width: 43vw;
    background-color: #5454f0;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    position: relative;
    border-radius: 2vw;
    z-index: 1;
    border: .2vw solid black;
    box-shadow: 0vw 0vw .3vw black;
}
.data::-webkit-scrollbar{
    display: none;
}
.design {
   height: 80vw;
   width: 80vw;
   border-radius: 50%;
   background-color: rgb(0, 42, 255, .8);
   border: .2vw solid black;
   position: absolute;
   top: -36vw;
   left: -32vw;

}
.warning{
   padding: 1vw;
   background-color: rgb(143, 17, 17);
   color: white;
   position: absolute;
   right: 0;
   display: none;
   border: .2vw solid white;
   border-radius: 10vw 0 0 10vw;
}
.form{
    height: 84%;
    width: auto;
    background-color:  transparent;
    display: flex;
    flex-direction: row;
    position: absolute;
    top: 1vw;
    left: .47vw;
    transition: .5s linear;
}
 .name, .education, .contact, .skills, .experience, .languages, .projects{ 
     height: 86%;
     width: 36vw;
     background-color: white;
     margin: 0 1vw;
     border-radius: 2vw;
     padding: 2vw;
     display: flex;
     flex-direction: column;
     align-items: start;
     justify-content: space-around;
     border: .2vw solid black;
 }
 .active1{
left: -42vw;
 }
 .active2{
    left: -84.25vw;
 }
 .active3{
    left: -126.5vw;
 }
 .active4{
    left: -168.9vw;
 }
 .active5{
    left: -211.1vw;
 }
 .active6{
   left: -253.4vw;
 }
 .form input{
   height: 3vw;
   width: 34vw;
   background-color: transparent;
   border: none;
   border-bottom: .2vw solid black;
   outline: none;
 }
 .input-capital{text-transform:capitalize;}
 .input-small{text-transform: lowercase;}
 .form h1{
   font-size: 2vw;
   color: black;
   background-color: transparent;
 }
 .suggetion{
   height: 9vw;
   width: 90%;
   background-color:transparent;
   display: flex;
   flex-direction: column;
   justify-content: space-between;
   align-items: start;
 }
 .suggetion h1{
   font-size: 1.8vw;
 }
 .suggetion li{
   font-size: 1.2vw;
   font-weight: 100;
 }
 .next {
    height: 8%;
    width: 15%;
    color: rgb(0, 42, 255, .8);
    background-color: white;
    position: absolute;
    top: 90%;
    left: 80%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    border-radius: .2vw;
    cursor: pointer;
    font-size: 1.5vw;
    font-family: 'system-ui';

 }
 .active-next{display: none;}
 .previous{
   height: 8%;
   width: 15%;
   color: rgb(0, 42, 255, .8);
   background-color: white;
   position: absolute;
   top: 90%;
   left: 5%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    border-radius: .2vw;
    cursor: pointer;
    font-size: 1.5vw;
    font-family: 'system-ui';
    display: none;
}
.active-previous{display: block;
               display: flex;}
 .submit{
    height: 8%;
    width: 15%;
    color: white;
    background-color: blue;
    position: absolute;
    top: 90%;
    left: 80%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: .2vw;
    cursor: pointer;
    font-size: 1.5vw;
    font-family: 'system-ui';
    border: .2vw solid white;
    display: none;
 }
 .submit-m{
  margin: 0 auto;
  padding: 1% 2%;
  background-color: #5454f0;
  color: white;
  border: .1vw solid black;
  border-radius: .2vw;
  display: none;
 }
 .active-submit{display: block;
                display: flex;}
 .result-body{
   height: 100%;
   width: 100vw;
   background-color: white;
   display: flex;
   align-items: center;
   justify-content: space-around;
   position: relative;
   overflow: hidden;
}
.final-design {
   height: 120vh;
   width: 90vw;
   background-color: rgb(0, 42, 255, .8);
   position: absolute;
   top: -25%;
   left: -37%;
   border-radius: 5vw;
   transform: rotate(30deg);
}
.main-resume-body{
   height: 40vw;
   width: 28.27vw;
   background-color: transparent;
   z-index: 1;
   border: .5vw solid black;
}
.download-body {
   height: 20vw;
   width: 30vw;
   background-color: white;
   border-radius: 2vw;
   display: flex;
   align-items: center;
   justify-content: space-around;
   flex-direction: column;
   z-index: 1;
   box-shadow: 0vw 0vw .2vw;
}
.file-name{
   width: 90%;
   height: 35%;
  background-color: transparent;
  display: flex;
  align-items: start;
  justify-content: space-evenly;
  flex-direction: column;
}
.file-name h1{
   font-size: 2vw;
   color:  rgb(0, 42, 255, .8);
}
.file-name h2{
   width: 94%;
   height: 45%;
   border: .1vw solid black;
   font-size: 1.8vw;
   padding: 0 2%;
}
.download-btn{
   height: 20%;
   width: 90%;
   background-color: rgb(0, 42, 255, .8);
   border: none;
   color: white;
   border-radius: 1vw;
   font-size: 2.2vw;
   display: flex;
   align-items: center;
   justify-content: center;
   gap: 1vw;
   cursor: pointer;
}
.download-pdf{
   height: 212.23905vw;
   width: 150vw;
}
@media (max-width: 915px) {
   .warning{
      font-size: 4vw;
      padding: 3vw;
      display: block;
      top: 2vw;
   }
   .design {
      height: 150vw;
      width: 150vw;
      top: -71vw;
      left: -73vw;
  }
  #main-back {
   font-size: 6vw;
  }
   .data {
      height: 65vw;
      width: 80vw;
  }
  .name, .education, .contact, .skills, .experience, .languages, .projects{ 
   width: 72.7vw;
  }
  .active1{
   left: -78.5vw;
    }
    .active2{
      left: -157.5vw;
    }
    .active3{
      left: -236.5vw;
    }
    .active4{
      left: -315.5vw;
    }
    .active5{
      left: -394.5vw;
    }
    .active6{
      left: -473.5vw;
    }
  .form h1 {
   font-size: 4vw;
  }
  .form input {
   height: 8vw;
   width: 72vw;
   font-size: 2.5vw;
}
.suggetion {
   height: 15vw;
}
.suggetion h1{
   font-size: 3.5vw;
 }
 .suggetion li{
   font-size: 2vw;
   font-weight: 100;
   margin-left: 1vw;
 }
.next{
   font-size: 3vw;
   top: 87%;
}
.previous{
   font-size: 3vw;
   top: 87%;
}
.submit{
   font-size: 3vw;
   top: 87%;
}
.submit-m{
   font-size: 3vw;
   border-radius: .7vw;
   display: block;
  }
  .active-submit-m{
   display: none;
}
.result-body {
   flex-direction: column;
   justify-content: space-evenly;
}
.final-design {
   height: 105vh;
   width: 150vw;
}
.main-resume-body {
   height: 90vw;
   width: 63.4vw;
}

.download-body {
   height: 30vw;
   width: 60vw;
}
.file-name h1 {
   font-size: 3vw;
}
.file-name h2{
   font-size: 4vw;
}
.download-btn {
   font-size: 4vw;
  }
}
@media (max-width: 600px) {
   #main-back {
      font-size: 8vw;
   }
   .data {
       height: 135vw;
   }
   .form {
      height: 92%;
   }
   .form h1 {
      font-size: 6vw;
  }
  .form input {
   height: 10vw;
   font-size: 4vw;
}
.suggetion {
   height: 35vw;
   width: 100%;
}
.suggetion h1 {
   font-size: 5.5vw;
}
.suggetion li {
   font-size: 4vw;
   margin-left: 4vw;
}
.next {
   font-size: 5vw;
   left: 65%;
   width: 30%;
   border-radius: 2vw;
}
.previous{
   font-size: 5vw;
   left: 5%;
   width: 30%;
   border-radius: 2vw;
}
.submit{
   font-size: 5vw;
   left: 65%;
   width: 30%;
   border-radius: 2vw;
}
.submit-m{
   font-size: 5vw;
   border-radius: 1vw;
   display: block;
  }
  .active-submit-m{
   display: none;
}
}