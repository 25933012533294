*{
    padding: 0;
    margin: 0;
    font-family: math ,system-ui, -apple-system, BlinkMacSystemFont;
}
.resume15{
     width: 100%;
     height: 100%;
     justify-content: space-between;
     align-items: center;
     flex-direction: column;
     display: flex;
}
.r15-name{
    height:15%;
    background-color: #103c81;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 0 3%;
    border-radius: 0 0 3vw 3vw;
}
.r15-name h1{
    font-size: 1.7vw;
    color: white;
    background-color: transparent;
    font-weight: 500;
}
.r15-name h2{
    font-size: 1.1vw;
    color: white;
    font-weight: 500;
    background-color: transparent;
}
.r15-d-name{
    height:15%;
    background-color: #103c81;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 0 3%;
    border-radius: 0 0 10vw 10vw;
}
.r15-d-name h1{
    font-size: 8vw;
    color: white;
    background-color: transparent;
    font-weight: 500;
}
.r15-d-name h2{
    font-size: 5vw;
    color: white;
    font-weight: 500;
    background-color: transparent;
}
.r15-main-con{
    width: 90%;
    height: 80%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 5%;

}
.r15-div{
    height: auto;
    width: 100%;
    background-color: transparent;
}
.r15-h1{
    font-size: 1.1vw;
    font-weight: 500;
    background-color: transparent;
    color: #103c81;
    width:100%;
    height: 1.5vw;
    margin: 2% 0;
    border-bottom: .1vw solid #103c81;
    border-top: .1vw solid #103c81;
    padding-top: 1%;
}
.r15-p{
    width: 100%;
    height: auto;
    font-size: .9vw;
    font-weight: 100;
    color: #103c81;

}
.r15-d-h1{
    font-size: 7vw;
    font-weight: 500;
    background-color: transparent;
    color: #103c81;
    width:100%;
    height: 9vw;
    margin: 2% 0;
    border-bottom: .5vw solid #103c81;
    border-top: .5vw solid #103c81;
    padding-bottom: 1%;
}
.r15-d-p{
    width: 100%;
    height: auto;
    font-size: 4.7vw;
    font-weight: 100;
    color: #103c81;

}
i{
    margin-right: 2%;
}
@media (max-width : 915px) {
    .r15-name{
        border-radius: 0 0 6vw 6vw;
    }
    .r15-name h1 {
        font-size: 3.5vw;
    }
    .r15-name h2 {
        font-size: 2.5vw;
    }
    .r15-h1 {
        font-size: 3vw;
        height: 3.5vw;
    }
    .r15-p {
        margin-top: 1vw;
        font-size: 2vw;
    }
}