*{
    padding: 0;
    margin: 0;
    font-family: system-ui, -apple-system, BlinkMacSystemFont;
}
.resume8{
     width: 100%;
     height: 100%;
     justify-content: center;
    align-items: center;
    flex-direction: column;
    display: flex;
}
.r8-name{
    height:15%;
    width: 100%;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.r8-name h1{
    font-size: 1.7vw;
    color: #71582a;
    background-color: transparent;
    font-weight: 500;
}
.r8-name h2{
    font-size: 1.5vw;
    color: #71582a;
    font-weight: 500;
    background-color: transparent;
}
.r8-d-name{
    height:15%;
    width: 100%;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.r8-d-name h1{
    font-size: 8vw;
    color: #71582a;
    background-color: transparent;
    font-weight: 500;
}
.r8-d-name h2{
    font-size: 6vw;
    color: #71582a;
    font-weight: 500;
    background-color: transparent;
}
.r8-main-con{
    width: 90%;
    height: 80%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

}
.r8-div{
    height: auto;
    width: 100%;
    background-color: transparent;
}
.r8-h1{
    font-size: 1.1vw;
    font-weight: 500;
    background-color:  #795548;
    color: white;
    width:95%;
    height: 1.5vw;
    padding: 1% 0 0 5%;
    margin: 2% 0;
}
.r8-p{
    width: 100%;
    height: auto;
    font-size: .9vw;
    font-weight: 100;
    color: #71582a;
}
.r8-d-h1{
    font-size: 7vw;
    font-weight: 500;
    background-color:  #795548;
    color: white;
    width:95%;
    height: 9vw;
    padding: 0 0 1% 5%;
    margin: 2% 0;
}
.r8-d-p{
    width: 100%;
    height: auto;
    font-size: 4.8vw;
    font-weight: 100;
    color: #71582a;
}
i{
    margin-right: 2%;
}
@media (max-width : 915px) {

    .r8-name h1 {
        font-size: 3.5vw;
    }
    .r8-name h2 {
        font-size: 2.5vw;
    }
    .r8-h1 {
        font-size: 3vw;
        height: 3.5vw;
    }
    .r8-p {
        margin-top: 1vw;
        font-size: 2vw;
    }
}