*{
    padding: 0;
    margin: 0;
    
}
.resume1{
     width: 100%;
     height: 100%;
}
.r1-name{
    height:25%;
    width: 100%;
    background-color: black;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.r1-d-name{
    height:25%;
    width: 100%;
    background-color: black;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.r1-name h1{
    font-size: 1.8vw;
    color: white;
    background-color: transparent;
    font-weight: 500;
    font-family: Arial, Helvetica, sans-serif;
}
.r1-name h2{
    font-size: 1.5vw;
    margin-top: 2%;
    color: black;
    font-weight: 500;
    background-color: white;
    padding: 2% 5%;
    font-family: Arial, Helvetica, sans-serif;
}
.r1-d-name h1{
    font-size: 9vw;
    color: white;
    background-color: transparent;
    font-weight: 500;
    font-family: Arial, Helvetica, sans-serif;
}
.r1-d-name h2{
    font-size: 7vw;
    margin-top: 2%;
    color: black;
    font-weight: 500;
    background-color: white;
    padding: 2% 5%;
    font-family: Arial, Helvetica, sans-serif;
}
.r1-main-con{
    width: 100%;
    height: 75%;
    display: flex;
    flex-direction: row;
}
.r1-part1, .r1-part2{
    height: 90%;
    width: 45%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.r1-part1{
    background-color: transparent;
    margin: auto 0 auto auto;
    border-right: .2vw solid black;
    align-items: start;
}
.r1-part2{
    background-color: transparent;
    margin: auto auto auto 0;
    border-left: .2vw solid black;
    align-items: end;
}
.r1-h1{
    font-size: 2vw;
    font-weight: 500;
    background-color: black;
    color: white;
    width:90%;
    height: 3vw;
    padding-left: 10%;
    display: flex;
    align-items: center;
    font-family: Arial, Helvetica, sans-serif;
}
.r1-p{
    width: 100%;
    height: auto;
    margin-top: .3vw;
    font-size: 1vw;
    font-weight: 100;
    font-family: Arial, Helvetica, sans-serif;
}
.r1-d-h1{
    font-size: 8vw;
    font-weight: 500;
    background-color: black;
    color: white;
    width:90%;
    height: 9vw;
    padding-left: 10%;
    display: flex;
    align-items: center;
    font-family: Arial, Helvetica, sans-serif;
}
.r1-d-p{
    width: 100%;
    height: auto;
    margin-top: 1.5vw;
    font-size: 5.1vw;
    font-weight: 100;
    font-family: Arial, Helvetica, sans-serif;
}
i{
    margin-right: 2%;
}
.r1-div{
    height: auto;
    width: 95%;
    display: flex;
    align-items: start;
    justify-content: space-between;
    flex-direction: column;
    background-color: transparent;
}
@media (max-width : 915px) {
    .r1-name h1 {
        font-size: 3.5vw;
    }
    .r1-name h2 {
        font-size: 2.5vw;
    }
    .r1-h1 {
        font-size: 3vw;
        height: 5vw;
    }
    .r1-p {
        margin-top: 1vw;
        font-size: 2vw;
    }
}