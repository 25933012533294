*{  padding: 0;
    margin: 0;
}
.footer{
    height: 30vw;
    width: 100%;
    background-color: #11114d;
    display: flex;
    align-items: center;
    justify-content: space-around;
}
.news-box{
    height: 60%;
    width: 40%;
    background-color:transparent;
}
.news-box h1{
    font-size: 2.5vw;
    color: white;
}
.news-box p{
    width: 70%;
    font-size: 1.5vw;
    color: white;
    font-weight: 100;
    margin-top: 2vw;
}
.news-box div{
    height: 3.2vw;
    width: 30vw;
    background-color: transparent;
    margin-top: 2vw;
    display: flex;
    align-items: center;
    justify-content: end;
    border-radius: .4vw;
    border: .1vw solid gray;
}
.news-box i{
    font-size: 1.3vw;
    margin: 0 3% 0 3%;
    color: gray;
}
.news-box input{
    height: 100%;
    border: none;
    outline: none;
    width: 57%;
    color: white;
    background-color: transparent;
}
.news-box input::placeholder{
    color: gray;
    font-size: 1.2vw;
}
.news-box button{
height: 100%;
border: none;
width: 33%;
font-size: 1.2vw;
color: white;
background-color: #6366f1;
border-top-right-radius: .4vw;
border-bottom-right-radius: .4vw;
transition: .2s linear;
cursor: pointer;
}
.news-box button:hover{
    background-color: rgb(21, 62, 184);
}
.office{
    height: 60%;
    width: 20%;
    background-color: transparent;
}
.office h1{
    font-size: 2.5vw;
    color: white;
}
.footer-contact{
    width: 100%;
    background-color:transparent;
    height: auto;
    display: flex;
    align-items: start;
    justify-content: end;
    font-size: 1.5vw;
    margin-top: 1vw;
    cursor: pointer;
}
.office p{
    width: 90%;
    color: white;
    transition: .2s linear;
    font-size: 1.5vw;
}
.footer-contact:hover p{
    color: blue;
}
.office i{
    margin: .4vw .8vw 0 0;
    color: blue;
    font-size: 1.5vw;
}
.payment{
    height: 60%;
    width: 30%;
    background-color:transparent;
}
.payment h1{
    font-size: 2.5vw;
    color: white;
}
.payment-method{
    height: 35%;
    width: 100%;
    background-color:transparent;
    display: flex;
    align-items: center;
    justify-content: start;
    flex-direction: row;
    gap: 2vw;
    margin-top: 1.5vw;
}
.payment-method i{
    font-size: 4vw;
    background-color:transparent;
    color: white;
}
.azimbashar{
    height: 3.5vw;
    font-size: 1.8vw;
    width: 100%;
    background-color:  #0b0f19;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    border-top: .1vw solid gray;
}
.azimbashar p{
    color:  #6366f1;
    margin-left: .5vw;
}
@media (max-width: 600px) {
    .footer{
        height: 185vw;
        flex-direction: column;
    }
    .news-box {
        height: 65vw;
        width: 90%;
        border-bottom: .1vw solid gray;
    }
    .news-box h1 {
        font-size: 10vw;
    }
    .news-box p {
        width: 100%;
        font-size: 4.5vw;
    }
    .news-box div {
        height: 10vw;
        width: 99%;
        margin-top: 6vw;
    }
    .news-box i {
        font-size: 6vw;
    }
    .news-box button {
        font-size: 4.2vw;
    }
    .news-box input {
        font-size: 5vw;
    }
    .news-box input::placeholder{
        font-size: 5vw;
    }
    .office {
        height: 50vw;
        width: 90%;
        border-bottom: .1vw solid gray;
    }
    .office h1 {
        font-size: 10vw;
    }
    .office p{
        font-size: 5.5vw;
    }
    .office i{
        font-size: 5.5vw;
    }
    .footer-contact{
        font-size: 4.5vw;
        margin-top: 3vw;
    }
    .payment {
        height: 40vw;
        width: 90%;
    }
    .payment h1 {
        font-size: 9vw;
    }
    .payment-method {
        height: 20vw;
        justify-content: space-around;
        gap: 0vw;
        margin-top: 4.5vw;
    }
    .payment-method i {
        font-size: 15vw;
    }
    .azimbashar {
        height: 10vw;
        font-size: 5vw;
    }
}