*{
    padding: 0;
    margin: 0;
    font-family: system-ui, -apple-system, BlinkMacSystemFont;
}
.resume3{
     width: 100%;
     height: 100%;
     justify-content: center;
    align-items: center;
    flex-direction: column;
    display: flex;
}
.r3-name{
    height:15%;
    width: 100%;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.r3-d-name{
    height:15%;
    width: 100%;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.r3-name h1{
    font-size: 2vw;
    color:  rgb(32, 32, 81);
    background-color: transparent;
    font-weight: 500;
}
.r3-name h2{
    font-size: 1.5vw;
    color: rgb(32, 32, 81);
    font-weight: 500;
    background-color: transparent;
}
.r3-d-name h1{
    font-size: 8vw;
    color:  rgb(32, 32, 81);
    background-color: transparent;
    font-weight: 500;
}
.r3-d-name h2{
    font-size: 5vw;
    color: rgb(32, 32, 81);
    font-weight: 500;
    background-color: transparent;
}
.r3-main-con{
    width: 90%;
    height: 80%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

}
.r3-div{
    height: auto;
    width: 100%;
    background-color: transparent;
}
.r3-h1{
    font-size: 1.1vw;
    font-weight: 500;
    background-color: rgb(109, 88, 248);
    color: white;
    width:95%;
    height: 1.5vw;
    padding: 1% 0 0 5%;
    margin: 2% 0;
}
.r3-p{
    width: 100%;
    height: auto;
    font-size: .9vw;
    font-weight: 100;
    color: rgb(32, 32, 81);
}
.r3-d-h1{
    font-size: 7vw;
    font-weight: 500;
    background-color: rgb(109, 88, 248);
    color: white;
    width:95%;
    height: 8vw;
    padding: 0 0 1% 5%;
    margin: 2% 0;
}
.r3-d-p{
    width: 100%;
    height: auto;
    font-size: 5vw;
    font-weight: 100;
    color: rgb(32, 32, 81);
}
i{
    margin-right: 2%;
}
@media (max-width : 915px) {

    .r3-name h1 {
        font-size: 3.5vw;
    }
    .r3-name h2 {
        font-size: 2.5vw;
    }
    .r3-h1 {
        font-size: 3vw;
        height: 3.5vw;
    }
    .r3-p {
        margin-top: 1vw;
        font-size: 2vw;
    }
}