*{
    padding: 0;
    margin: 0;
    
    
}
.resume5{
     width: 100%;
     height: 100%;
}
.r5-name{
    height:20%;
    width: 100%;
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.r5-name h1{
    font-size: 1.6vw;
    color: rgb(66, 20, 20);
    background-color: transparent;
    font-weight: 500;
    font-family: sarif, Arial, Helvetica, sans-serif;
}
.r5-name h2{
    font-size: 1vw;
    margin-top: 2%;
    color:  rgb(66, 20, 20);
    font-weight: 500;
    background-color: transparent;
    padding: 2% 5%;
    letter-spacing: .3vw;
    font-family: sarif, Arial, Helvetica, sans-serif;
}
.r5-d-name{
    height:20%;
    width: 100%;
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.r5-d-name h1{
    font-size: 8vw;
    color: rgb(66, 20, 20);
    background-color: transparent;
    font-weight: 500;
    font-family: sarif, Arial, Helvetica, sans-serif;
}
.r5-d-name h2{
    font-size: 5.5vw;
    margin-top: 2%;
    color:  rgb(66, 20, 20);
    font-weight: 500;
    background-color: transparent;
    padding: 2% 5%;
    letter-spacing: .5vw;
    font-family: sarif, Arial, Helvetica, sans-serif;
}
.r5-main-con{
    width: 100%;
    height: 80%;
    display: flex;
    flex-direction: row;
}
.r5-part1, .r5-part2{
    height: 95%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}
.r5-part1{
    border-radius: .5vw;
    margin: 0 0 auto auto;
    align-items: start;
    width: 35%;
    background-color:#f3dfe2;
    padding-left: 2%;
}
.r5-part2{
    background-color: transparent;
    margin: 0 auto auto 0;
    align-items: end;
    width: 55%;
}
.r5-h1{
    font-size: 1.3vw;
    font-weight: 700;
    background-color: transparent;
    color:  rgb(66, 20, 20);
    display: flex;
    align-items: center;
    font-family: sarif, Arial, Helvetica, sans-serif;
    margin-bottom: 3%;
    letter-spacing: .2vw;
}
.r5-p{
    width: 100%;
    height: auto;
    margin-top: .3vw;
    font-size: .9vw;
    font-weight: 100;
    letter-spacing: .05vw;
    font-family: sarif, Arial, Helvetica, sans-serif;
    color: rgb(66, 20, 20);
}
i{
    margin-right: 2%;
}
.r5-d-h1{
    font-size: 7vw;
    font-weight: 700;
    background-color: transparent;
    color:  rgb(66, 20, 20);
    display: flex;
    align-items: center;
    font-family: sarif, Arial, Helvetica, sans-serif;
    margin-bottom: 3%;
    letter-spacing: .5vw;
}
.r5-d-p{
    width: 100%;
    height: auto;
    margin-top: .7vw;
    font-size: 4.5vw;
    font-weight: 100;
    letter-spacing: .5vw;
    font-family: sarif, Arial, Helvetica, sans-serif;
    color: rgb(66, 20, 20);
}
i{
    margin-right: 2%;
}
.r5-div{
    height: auto;
    width: 95%;
    display: flex;
    align-items: start;
    justify-content: space-between;
    flex-direction: column;
    background-color: transparent;
}
@media (max-width : 915px) {

    .r5-name h1 {
        font-size: 3.5vw;
    }
    .r5-name h2 {
        font-size: 2.5vw;
    }
    .r5-h1 {
        font-size: 3vw;
        height: 3.5vw;
    }
    .r5-p {
        margin-top: 1vw;
        font-size: 2vw;
    }
}
