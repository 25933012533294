*{
    padding: 0;
    margin: 0;
    
    
}
.resume6{
     width: 100%;
     height: 100%;
     display: flex;
     justify-content: end;
     flex-direction: column;
}
.r6-name{
    height:15%;
    width: 90%;
    background-color:#e1f3df;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: 0 auto;
    border-radius: 0 2vw 2vw 0;
    border-bottom: .1vw solid #e1f3df;
}
.r6-d-name{
    height:15%;
    width: 90%;
    background-color:#e1f3df;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: 0 auto;
    border-radius: 0 10vw 10vw 0;
    border-bottom: .1vw solid #e1f3df;
}
.r6-name h1{
    font-size: 1.6vw;
    color: rgb(29, 86, 29);
    background-color: transparent;
    font-weight: 500;
    font-family: sarif, Arial, Helvetica, sans-serif;
}
.r6-name h2{
    font-size: 1vw;
    margin-top: 2%;
    color:   rgb(29, 86, 29);
    font-weight: 500;
    background-color: transparent;
    padding: 2% 5%;
    letter-spacing: .5vw;
    font-family: sarif, Arial, Helvetica, sans-serif;
}
.r6-d-name h1{
    font-size: 8vw;
    color: rgb(29, 86, 29);
    background-color: transparent;
    font-weight: 500;
    font-family: sarif, Arial, Helvetica, sans-serif;
}
.r6-d-name h2{
    font-size: 5vw;
    margin-top: 2%;
    color:   rgb(29, 86, 29);
    font-weight: 500;
    background-color: transparent;
    padding: 2% 5%;
    letter-spacing: 1vw;
    font-family: sarif, Arial, Helvetica, sans-serif;
}
.r6-main-con{
    width: 100%;
    height: 80%;
    display: flex;
    flex-direction: row;
}
.r6-part1, .r6-part2, .r6-d-part1{
    height: 95%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}
.r6-part1{
    margin: 0 0 auto auto;
    align-items: start;
    width: 33%;
    background-color:#e1f3df;
    padding-left: 2%;
    border-radius: 0 0 2vw 2vw;
}
.r6-d-part1{
    margin: 0 0 auto auto;
    align-items: start;
    width: 33%;
    background-color:#e1f3df;
    padding-left: 2%;
    border-radius: 0 0 10vw 10vw;
}
.r6-part2{
    background-color: transparent;
    margin: 0 auto auto 0;
    align-items: end;
    width: 55%;
}
.r6-h1{
    font-size: 1.3vw;
    font-weight: 700;
    background-color: transparent;
    color:  rgb(29, 86, 29);
    display: flex;
    align-items: center;
    font-family: sarif, Arial, Helvetica, sans-serif;
    margin-bottom: 3%;
    letter-spacing: .2vw;
}
.r6-d-h1{
    font-size: 7vw;
    font-weight: 700;
    background-color: transparent;
    color:  rgb(29, 86, 29);
    display: flex;
    align-items: center;
    font-family: sarif, Arial, Helvetica, sans-serif;
    margin-bottom: 3%;
    letter-spacing: .5vw;
}
.r6-p{
    width: 100%;
    height: auto;
    margin-top: .3vw;
    font-size: .9vw;
    font-weight: 100;
    letter-spacing: .05vw;
    font-family: sarif, Arial, Helvetica, sans-serif;
    color:  rgb(29, 86, 29);
}
.r6-d-p{
    width: 100%;
    height: auto;
    margin-top: .5vw;
    font-size: 4.8vw;
    font-weight: 100;
    letter-spacing: .5vw;
    font-family: sarif, Arial, Helvetica, sans-serif;
    color:  rgb(29, 86, 29);
}
i{
    margin-right: 2%;
}
.r6-div{
    height: auto;
    width: 95%;
    display: flex;
    align-items: start;
    justify-content: space-between;
    flex-direction: column;
    background-color: transparent;
}
@media (max-width : 915px) {

    .r6-name h1 {
        font-size: 3.5vw;
    }
    .r6-name h2 {
        font-size: 2.5vw;
    }
    .r6-h1 {
        font-size: 3vw;
        height: 3.5vw;
    }
    .r6-p {
        margin-top: 1vw;
        font-size: 2vw;
    }
}