*{
    padding: 0;
    margin: 0;
}
.loader{
    height: 100vh;
    width: 100%;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0px;
    left: 0px;
    z-index: 10000000;
  }
  .active-loader{
    display: none;
  }
.header{
    width: 100%;
    height: 6vw;
    background-color: rgb(0, 42, 255, .8);
    border-bottom: .1vw solid black;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    position: fixed;
    left: 0;
    top: 0;
}
.header-logo{
    height: 100%;
    margin-left: 2%;
}
#three-dot{
  font-size: 3vw;
  color: white;
 margin-right: 3vw;
 cursor: pointer;
 transition: .2s linear;
}
.header i:hover{
    color: black;
}
#main-back2-i{
    border-radius: 2vw;
     font-size: 2vw;
     position: fixed;
     top: 2vw;
     left: 2vw;
     background: white;
     padding: .3vw 1vw;
     cursor: pointer;
     box-shadow: 0vw 0vw 0.5vw white;
     z-index: 1;
 }
 .active-main-back2{
    display: none;
 }
.top-body{
    width: 100vw;
    height: 55vw;
    background-color: white;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: start;
    margin-top: 6vw;
}
.left-top-body{
    width: 40vw;
    height: 60%;
    background-color: transparent;
    display: flex;
    align-items: start;
    justify-content: space-evenly;
    flex-direction: column;
    margin-top: 5vw;

}
.left-top-body h1{
    font-size: 4vw;
    background-color: transparent;
    color:  rgb(0, 42, 255, .8);
}
.left-top-body button{
    height: 3.5vw;
    width: 10vw;
    font-size: 1.8vw;
    color: white;
    background-color: rgb(0, 42, 255, .8);
    border: none;
    border-radius: .4vw;
    cursor: pointer;
    transition: .3s linear;
}
.left-top-body button:hover{
    background-color: rgb(0, 42, 255, .7);
    box-shadow: 0vw 0vw .3vw black;
    color: black;
}
.left-top-body p{
    width: 80%;
    font-size: 1.5vw;
    color: black;
}
.right-top-body{
    width: 50vw;
    height: 100%;
    background-color: transparent;
}
.right-top-body img{
    width: 100%;
}
.center-body{
    width: 90%;
    height: auto;
    margin: 0vw auto;
    background-color: transparent;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
}
.sample-img{
    height: 35vw;
    border: .3vw solid rgb(0, 42, 255, .8);
    margin: 2vw;
}
html::-webkit-scrollbar{
    display: none;
}


@media (max-width: 915px) {
 .header{
    height: 12vw;
 }
 #three-dot{
    font-size: 5vw;
 }
 .top-body{
    height: 100vw;
    height: 176vw;
    background-color: transparent;
    flex-direction: column;
    align-items: center;
    margin-top: 12vw;
}
.left-top-body{
    width: 90%;
    height: 145vw;
}
.left-top-body h1 {
    font-size: 8vw;
}
.left-top-body p {
    width: 100%;
    font-size: 4vw;
}
.right-top-body{
    width: 90%;
}
.left-top-body button {
    height: 10vw;
    width: 26vw;
    font-size: 4vw;
    border-radius: 2vw;
}
#main-back2-i{
     font-size: 7vw;
    }
.sample-img {
    height: 55vw;
    border: .5vw solid rgb(0, 42, 255, .8);
}
}

@media (max-width: 600px) {
    .header {
        height: 18vw;
    }
    #three-dot{
        font-size: 8vw;
        margin-right: 5vw;
    }
    .top-body{
        margin-top: 18vw;
    }
    .left-top-body button {
        height: 13vw;
        width: 35vw;
        font-size: 5.5vw;
    }
    #main-back2-i{
        font-size: 9vw;
       }
}
