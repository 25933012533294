*{
    padding: 0;
    margin: 0;
    
    
}
.resume7{
     width: 100%;
     height: 100%;
}
.r7-name{
    height:15%;
    width: 100%;
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: end;
    flex-direction: column;
}
.r7-name h1{
    font-size: 1.6vw;
    color: #e47444;
    background-color: transparent;
    font-weight: 500;
    font-family: sarif, Arial, Helvetica, sans-serif;
}
.r7-name h2{
    font-size: 1vw;
    color: #f2a189;
    font-weight: 500;
    background-color: transparent;
    padding: 2% 5%;
    letter-spacing: .2vw;
    font-family: sarif, Arial, Helvetica, sans-serif;
}
.r7-d-name{
    height:15%;
    width: 100%;
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: end;
    flex-direction: column;
}
.r7-d-name h1{
    font-size: 8vw;
    color: #e47444;
    background-color: transparent;
    font-weight: 500;
    font-family: sarif, Arial, Helvetica, sans-serif;
}
.r7-d-name h2{
    font-size: 5vw;
    color: #f2a189;
    font-weight: 500;
    background-color: transparent;
    padding: 2% 5%;
    letter-spacing: .5vw;
    font-family: sarif, Arial, Helvetica, sans-serif;
}
.r7-main-con{
    width: 100%;
    height: 86%;
    display: flex;
    flex-direction: row;
}
.r7-part1, .r7-part2{
    height: 95%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}
.r7-part1{
    height: 93%;
    border-radius: 100vw 100vw 0 0;
    margin: 0 0 auto auto;
    align-items: start;
    width: 32%;
    background-color: #f2a189;
    padding: 8% 0 0 2%;
}
.r7-part2{
    background-color: transparent;
    margin: 0 auto auto 0;
    align-items: end;
    width: 67%;
}
.r7-h1{
    font-size: 1.3vw;
    font-weight: 700;
    background-color: transparent;
    color: white;
    display: flex;
    align-items: center;
    font-family: sarif, Arial, Helvetica, sans-serif;
    margin-bottom: 3%;
    letter-spacing: .2vw;
}
.r7-part2 h1{
    border-bottom: .1vw solid;
    width: 90%;
    padding-bottom: .4vw;
    color: #e47444;
}
.r7-p{
    width: 95%;
    height: auto;
    margin-top: .3vw;
    font-size: .9vw;
    font-weight: 100;
    letter-spacing: .05vw;
    font-family: sarif, Arial, Helvetica, sans-serif;
    color: black;
}
.r7-d-h1{
    font-size: 8vw;
    font-weight: 700;
    background-color: transparent;
    color: white;
    display: flex;
    align-items: center;
    font-family: sarif, Arial, Helvetica, sans-serif;
    margin-bottom: 3%;
    letter-spacing: .5vw;
}
.r7-d-p{
    width: 95%;
    height: auto;
    margin-top: .5vw;
    font-size: 5vw;
    font-weight: 100;
    letter-spacing: .3vw;
    font-family: sarif, Arial, Helvetica, sans-serif;
    color: black;
}
.r7-part2 i{ 
    margin-right: 2%;
    color: #e47444;
}
.r7-div{
    height: auto;
    width: 95%;
    display: flex;
    align-items: start;
    justify-content: space-between;
    flex-direction: column;
    background-color: transparent;
}
@media (max-width : 915px) {

    .r7-name h1 {
        font-size: 3.5vw;
    }
    .r7-name h2 {
        font-size: 2.5vw;
    }
    .r7-h1 {
        font-size: 3vw;
        height: 3.5vw;
    }
    .r7-p {
        margin-top: 1vw;
        font-size: 2vw;
    }
}
