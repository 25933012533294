*{
    padding: 0;
    margin: 0;
    
    
}
.resume11{
     width: 100%;
     height: 100%;
     display: flex;
     justify-content: end;
     flex-direction: column;
}
.design-r11 {
    height: 40%;
    width: 95%;
    background-color: #ccb6ed;
    position: absolute;
    top: 50%;
    left: 0;
    border-radius: 0 10vw 10vw 0;
    margin: auto;
}
.r11-name{
    height:15%;
    width: 100%;
    background-color: transparent;
    display: flex;
    align-items: start;
    justify-content: start;
    margin: 0 auto;
    position: relative;
}
.r11-name h1{
    font-size: 1.6vw;
    color: #311161;
    background-color: transparent;
    font-weight: 500;
    font-family: sarif, Arial, Helvetica, sans-serif;
    margin-left: 8%;
}
.r11-name h2{
    font-size: 1vw;
    margin-top: 2%;
    color: #311161;
    font-weight: 500;
    background-color: transparent;
    padding: 1% 10%;
    letter-spacing: .3vw;
    font-family: sarif, Arial, Helvetica, sans-serif;
}
.r11-d-name{
    height:15%;
    width: 100%;
    background-color: transparent;
    display: flex;
    align-items: start;
    justify-content: start;
    margin: 0 auto;
    position: relative;
}
.r11-d-name h1{
    font-size: 8vw;
    color: #311161;
    background-color: transparent;
    font-weight: 500;
    font-family: sarif, Arial, Helvetica, sans-serif;
    margin-left: 8%;
}
.r11-d-name h2{
    font-size: 5vw;
    margin-top: 2%;
    color: #311161;
    font-weight: 500;
    background-color: transparent;
    padding: 1% 10%;
    letter-spacing: .7vw;
    font-family: sarif, Arial, Helvetica, sans-serif;
}
.r11-main-con{
    width: 100%;
    height: 80%;
    display: flex;
    flex-direction: row;
}
.r11-part1, .r11-part2{
    height: 95%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}
.r11-part1{
    margin: 0 0 auto auto;
    align-items: start;
    width: 33%;
    background-color: transparent;
    padding-left: 2%;
}
.r11-part2{
    background-color: #ccb6ed;
    margin: 0 auto auto 0;
    align-items: end;
    width: 55%;
    border-radius: 1vw;
}
.r11-h1{
    font-size: 1.3vw;
    font-weight: 700;
    background-color: transparent;
    color:  #311161;
    display: flex;
    align-items: center;
    font-family: sarif, Arial, Helvetica, sans-serif;
    margin-bottom: 3%;
    letter-spacing: .2vw;
}
.r11-p{
    width: 100%;
    height: auto;
    margin-top: .3vw;
    font-size: .9vw;
    font-weight: 100;
    letter-spacing: .05vw;
    font-family: sarif, Arial, Helvetica, sans-serif;
    color:  #311161;
}
.r11-d-h1{
    font-size: 7vw;
    font-weight: 700;
    background-color: transparent;
    color:  #311161;
    display: flex;
    align-items: center;
    font-family: sarif, Arial, Helvetica, sans-serif;
    margin-bottom: 3%;
    letter-spacing: .5vw;
}
.r11-d-p{
    width: 100%;
    height: auto;
    margin-top: .5vw;
    font-size: 4.8vw;
    font-weight: 100;
    letter-spacing: .5vw;
    font-family: sarif, Arial, Helvetica, sans-serif;
    color:  #311161;
}
i{
    margin-right: 2%;
}
.r11-div{
    height: auto;
    width: 95%;
    display: flex;
    align-items: start;
    justify-content: space-between;
    flex-direction: column;
    background-color: transparent;
}
@media (max-width : 915px) {
     .r11-part2{
        border-radius: 2vw;
     }
    .r11-name h1 {
        font-size: 3.5vw;
    }
    .r11-name h2 {
        font-size: 2.5vw;
    }
    .r11-h1 {
        font-size: 3vw;
        height: 3.5vw;
    }
    .r11-p {
        margin-top: 1vw;
        font-size: 2vw;
    }
}