*{
    padding: 0;
    margin: 0;
    
    
}
.resume17{
     width: 100%;
     height: 100%;
}
.r17-name {
    height: 20%;
    width: 100%;
    background-color: #903306;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0 0 5vw 5vw;
    flex-direction: column;
}
.r17-name h1{
    font-size: 1.6vw;
    color: white;
    background-color: transparent;
    font-weight: 500;
    font-family: sarif, Arial, Helvetica, sans-serif;
}
.r17-name h2{
    font-size: 1vw;
    margin-top: 2%;
    color:  white;
    font-weight: 500;
    background-color: transparent;
    padding: 2% 5%;
    letter-spacing: .3vw;
    font-family: sarif, Arial, Helvetica, sans-serif;
}
.r17-d-name {
    height: 20%;
    width: 100%;
    background-color: #903306;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0 0 20vw 20vw;
    flex-direction: column;
}
.r17-d-name h1{
    font-size: 8vw;
    color: white;
    background-color: transparent;
    font-weight: 500;
    font-family: sarif, Arial, Helvetica, sans-serif;
}
.r17-d-name h2{
    font-size: 7vw;
    margin-top: 2%;
    color:  white;
    font-weight: 500;
    background-color: transparent;
    padding: 2% 5%;
    letter-spacing: .5vw;
    font-family: sarif, Arial, Helvetica, sans-serif;
}
.r17-main-con {
    width: 100%;
    height: 77%;
    display: flex;
    flex-direction: row;
    padding-top: 4%;
    border-radius: 5vw 5vw 0 0;
    background: #903306;
    margin-top: .2vw;
}
.r17-d-main-con {
    width: 100%;
    height: 77%;
    display: flex;
    flex-direction: row;
    padding-top: 4%;
    border-radius: 20vw 20vw 0 0;
    background: #903306;
    margin-top: .5vw;
}
.r17-part1, .r17-part2, .r17-d-part1{
    height: 92%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding-top: 5%;
}
.r17-part1{
    margin: 0 0 auto auto;
    align-items: start;
    width: 35%;
    background-color: transparent;
    padding-left: 2%;
    border-radius: 4vw 0 0 0;
    background-color: white;
}
.r17-d-part1{
    margin: 0 0 auto auto;
    align-items: start;
    width: 35%;
    background-color: transparent;
    padding-left: 2%;
    border-radius: 16vw 0 0 0;
    background-color: white;
}
.r17-part2{
    background-color: transparent;
    margin: 0 auto auto 0;
    align-items: end;
    width: 55%;
}
.r17-h1{
    font-size: 1.3vw;
    font-weight: 700;
    background-color: transparent;
    color: #903306;
    display: flex;
    align-items: center;
    font-family: sarif, Arial, Helvetica, sans-serif;
    margin-bottom: 3%;
    letter-spacing: .2vw;
}
.r17-p{
    width: 100%;
    height: auto;
    margin-top: .3vw;
    font-size: .9vw;
    font-weight: 100;
    letter-spacing: .05vw;
    font-family: sarif, Arial, Helvetica, sans-serif;
    color: #903306;
}
.r17-d-h1{
    font-size: 7vw;
    font-weight: 700;
    background-color: transparent;
    color: #903306;
    display: flex;
    align-items: center;
    font-family: sarif, Arial, Helvetica, sans-serif;
    margin-bottom: 3%;
    letter-spacing: .5vw;
}
.r17-d-p{
    width: 100%;
    height: auto;
    margin-top: .5vw;
    font-size: 4.7vw;
    font-weight: 100;
    letter-spacing: .5vw;
    font-family: sarif, Arial, Helvetica, sans-serif;
    color: #903306;
}
.r17-part2 h1 {
    color: white;
}
.r17-part2 p{
    color: white;
}
i{
    margin-right: 2%;
}
.r17-div{
    height: auto;
    width: 95%;
    display: flex;
    align-items: start;
    justify-content: space-between;
    flex-direction: column;
    background-color: transparent;
}
@media (max-width : 915px) {

    .r17-name h1 {
        font-size: 3.5vw;
    }
    .r17-name h2 {
        font-size: 2.5vw;
    }
    .r17-h1 {
        font-size: 3vw;
        height: 3.5vw;
    }
    .r17-p {
        margin-top: 1vw;
        font-size: 2vw;
    }
}
