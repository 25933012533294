*{
    padding: 0;
    margin: 0;
    font-family: math ,system-ui, -apple-system, BlinkMacSystemFont;
}
.resume4{
     width: 100%;
     height: 100%;
     justify-content: center;
    align-items: center;
    flex-direction: column;
    display: flex;
}
.r4-name{
    height:15%;
    width: 100%;
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: start;
    flex-direction: row;
}
.r4-name h1{
    font-size: 1.8vw;
    width: 45%;
    color: #142a6d;
    background-color: transparent;
    font-weight: 500;
    margin-left: 5%;
}
.r4-name h2{
    font-size: 1.1vw;
    color: white;
    font-weight: 500;
    background-color: transparent;
    position: absolute;
    top: 9%;
    left: 66%;

}
.r4-d-name{
    height:15%;
    width: 100%;
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: start;
    flex-direction: row;
}
.r4-d-name h1{
    font-size: 8vw;
    width: 45%;
    color: #142a6d;
    background-color: transparent;
    font-weight: 500;
    margin-left: 5%;
}
.r4-d-name h2{
    font-size: 5vw;
    color: white;
    font-weight: 500;
    background-color: transparent;
    position: absolute;
    top: 9%;
    left: 66%;
}
.circle {
    height: 51%;
    width: 83%;
    background-color: royalblue;
    position: absolute;
    top: -30%;
    border-radius: 50%;
    left: 46%;
}
 
.r4-main-con{
    width: 90%;
    height: 80%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

}
.r4-div{
    height: auto;
    width: 100%;
    background-color: transparent;
}
.r4-h1{
    font-size: 1.1vw;
    font-weight: 500;
    background-color: transparent;
    color: royalblue;
    width:100%;
    height: 1.5vw;
    margin: 2% 0;
    border-bottom: .2vw solid royalblue;
}
.r4-d-h1{
    font-size: 8vw;
    font-weight: 500;
    background-color: transparent;
    color: royalblue;
    width:100%;
    height: 11vw;
    margin: 2% 0;
    border-bottom: .2vw solid royalblue;
}
.r4-p{
    width: 100%;
    height: auto;
    font-size: .9vw;
    font-weight: 100;
    color: #142a6d;

}
.r4-d-p{
    width: 100%;
    height: auto;
    font-size: 4vw;
    font-weight: 100;
    color: #142a6d;

}
i{
    margin-right: 2%;
}
@media (max-width : 915px) {

    .r4-name h1 {
        font-size: 3.5vw;
    }
    .r4-name h2 {
        font-size: 2.5vw;
    }
    .r4-h1 {
        font-size: 3vw;
        height: 3.5vw;
    }
    .r4-p {
        margin-top: 1vw;
        font-size: 2vw;
    }
}