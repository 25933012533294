*{
    padding: 0;
    margin: 0;   
}
.resume13{
     width: 100%;
     height: 100%;
}
.design-r13{
    height: 12%;
    width: 55%;
    border-radius: 50%;
    background-color: #103c81;
    border: .5vw solid white;
    margin: 0  auto;
}
.design-d-r13{
    height: 12%;
    width: 55%;
    border-radius: 50%;
    background-color: #103c81;
    border: 2vw solid white;
    margin: 0  auto;
}
.r13-name{
    height:15%;
    width: 100%;
    background-color: transparent;
    display: flex;
    align-items: start;
    justify-content: center;
    flex-direction: column;
}
.r13-name h1{
    font-size: 1.5vw;
    color: #103c81;
    background-color: transparent;
    font-weight: 500;
    letter-spacing: .1vw;
    margin-left: 5%;
    font-family: sarif, Arial, Helvetica, sans-serif;
}
.r13-name h2{
    font-size: 1vw;
    color: #103c81;
    font-weight: 500;
    background-color: transparent;
    padding-top: 2%;
    margin-left: 5%;
    letter-spacing: .2vw;
    font-family: sarif, Arial, Helvetica, sans-serif;
}
.r13-d-name{
    height:15%;
    width: 100%;
    background-color: transparent;
    display: flex;
    align-items: start;
    justify-content: center;
    flex-direction: column;
}
.r13-d-name h1{
    font-size: 7vw;
    color: #103c81;
    background-color: transparent;
    font-weight: 500;
    letter-spacing: .2vw;
    margin-left: 5%;
    font-family: sarif, Arial, Helvetica, sans-serif;
}
.r13-d-name h2{
    font-size: 4.7vw;
    color: #103c81;
    font-weight: 500;
    background-color: transparent;
    padding-top: 2%;
    margin-left: 5%;
    letter-spacing: .7vw;
    font-family: sarif, Arial, Helvetica, sans-serif;
}
.r13-main-con{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
}
.r13-part1, .r13-part2{
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}
.r13-part1{
    margin: 0 0 auto auto;
    align-items: start;
    width: 32%;
    background-color: #103c81;
    padding-left: 3%;
    
}
.r13-part1 p{
    color: white;
}
.r13-part2{
    background-color: transparent;
    margin: 0 auto auto 0;
    align-items: end;
    width: 67%;
}
.r13-h1{
    font-size: 1.3vw;
    font-weight: 700;
    background-color: transparent;
    color: white;
    display: flex;
    align-items: center;
    font-family: sarif, Arial, Helvetica, sans-serif;
    margin-bottom: 3%;
    letter-spacing: .2vw;
}
.r13-part2 h1{
    border-bottom: .1vw solid;
    width: 90%;
    padding-bottom: .4vw;
    color: #103c81;
}
.r13-p{
    width: 95%;
    height: auto;
    margin-top: .3vw;
    font-size: .9vw;
    font-weight: 100;
    letter-spacing: .05vw;
    font-family: sarif, Arial, Helvetica, sans-serif;
    color: #103c81;
}
.r13-d-h1{
    font-size: 7vw;
    font-weight: 700;
    background-color: transparent;
    color: white;
    display: flex;
    align-items: center;
    font-family: sarif, Arial, Helvetica, sans-serif;
    margin-bottom: 3%;
    letter-spacing: .5vw;
}
.r13-d-p{
    width: 95%;
    height: auto;
    margin-top: .5vw;
    font-size: 4.8vw;
    font-weight: 100;
    letter-spacing: .05vw;
    font-family: sarif, Arial, Helvetica, sans-serif;
    color: #103c81;
}
.r13-part2 i{ 
    margin-right: 2%;
    color: #103c81;
}
.r13-div{
    height: auto;
    width: 95%;
    display: flex;
    align-items: start;
    justify-content: space-between;
    flex-direction: column;
    background-color: transparent;
}
@media (max-width : 915px) {
    .design-r13{
        border: .8vw solid white;
    }
    .r13-name h1 {
        font-size: 3.5vw;
    }
    .r13-name h2 {
        font-size: 2.5vw;
    }
    .r13-h1 {
        font-size: 3vw;
        height: 3.5vw;
    }
    .r13-p {
        margin-top: 1vw;
        font-size: 2vw;
    }
}
