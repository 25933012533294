*{
    padding: 0;
    margin: 0;   
}
.resume14{
     width: 100%;
     height: 100%;
     display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-direction: column;
}
.design-r14 {
    position: absolute;
    margin: 0 auto;
    left: 82%;
    height: 58%;
    width: 12%;
    background-color: purple;
    border: .5vw solid white;
}
.r14-name{
    width: 100%;
    padding: 1% 0;
    background-color: purple;
    display: flex;
    align-items: start;
    justify-content: center;
    flex-direction: column;
    margin: 5% auto;
    position: relative;
}

.r14-name h1{
    width: 77%;
    font-size: 1.6vw;
    color: white;
    background-color: transparent;
    font-weight: 500;
    margin-left: 3%;
    font-family: sarif, Arial, Helvetica, sans-serif;
}
.r14-name h2{
    font-size: 1vw;
    color: white;
    font-weight: 500;
    background-color: transparent;
    margin-left: 3%;
    letter-spacing: .2vw;
    font-family: sarif, Arial, Helvetica, sans-serif;
}
.design-d-r14 {
    position: absolute;
    margin: 0 auto;
    left: 82%;
    height: 58%;
    width: 12%;
    background-color: purple;
    border: 2vw solid white;
}
.r14-d-name{
    width: 100%;
    padding: 1% 0;
    background-color: purple;
    display: flex;
    align-items: start;
    justify-content: center;
    flex-direction: column;
    margin: 5% auto;
    position: relative;
}

.r14-d-name h1{
    width: 77%;
    font-size: 8vw;
    color: white;
    background-color: transparent;
    font-weight: 500;
    margin-left: 3%;
    font-family: sarif, Arial, Helvetica, sans-serif;
}
.r14-d-name h2{
    font-size: 5vw;
    color: white;
    font-weight: 500;
    background-color: transparent;
    margin-left: 3%;
    letter-spacing: .5vw;
    font-family: sarif, Arial, Helvetica, sans-serif;
}
.r14-main-con{
    width: 100%;
    height: 85%;
    display: flex;
    flex-direction: row;
}
.r14-part1, .r14-part2{
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}
.r14-part1{
    margin: 0 0 auto auto;
    align-items: start;
    width: 32%;
    background-color: purple;
    padding-left: 3%;
    border-radius: 0 5vw 0 0 ;
}
.r14-part1 p{
    color: white;
}
.r14-part2{
    background-color: transparent;
    margin: 0 auto auto 0;
    align-items: end;
    width: 67%;
}
.r14-h1{
    font-size: 1.3vw;
    font-weight: 700;
    background-color: transparent;
    color: white;
    display: flex;
    align-items: center;
    font-family: sarif, Arial, Helvetica, sans-serif;
    margin-bottom: 3%;
    letter-spacing: .2vw;
}
.r14-part2 h1{
    border-bottom: .1vw solid;
    width: 90%;
    padding-bottom: .4vw;
    color: purple;
}
.r14-p{
    width: 95%;
    height: auto;
    margin-top: .3vw;
    font-size: .9vw;
    font-weight: 100;
    letter-spacing: .05vw;
    font-family: sarif, Arial, Helvetica, sans-serif;
    color: purple;
}
.r14-d-h1{
    font-size: 7vw;
    font-weight: 700;
    background-color: transparent;
    color: white;
    display: flex;
    align-items: center;
    font-family: sarif, Arial, Helvetica, sans-serif;
    margin-bottom: 3%;
    letter-spacing: .5vw;
}
.r14-d-p{
    width: 95%;
    height: auto;
    margin-top: .5vw;
    font-size: 4.8vw;
    font-weight: 100;
    letter-spacing: .5vw;
    font-family: sarif, Arial, Helvetica, sans-serif;
    color: purple;
}
.r14-part2 i{ 
    margin-right: 2%;
    color: purple;
}
.r14-div{
    height: auto;
    width: 95%;
    display: flex;
    align-items: start;
    justify-content: space-between;
    flex-direction: column;
    background-color: transparent;
}
@media (max-width : 915px) {
    .design-r14{
        border: .8vw solid white;
    }
    .r14-part1{
        border-radius: 0 10vw 0 0;
    }
    .r14-name h1 {
        font-size: 3vw;
    }
    .r14-name h2 {
        font-size: 2vw;
    }
    .r14-h1 {
        font-size: 3vw;
        height: 3.5vw;
    }
    .r14-p {
        margin-top: 1vw;
        font-size: 2vw;
    }
}
